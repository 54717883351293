<template>
    <v-card style="border-radius: 10px">
      <div class="container grey--text">
        <v-layout wrap>
            <v-flex xs12 >
                <v-textarea outline
                    name="input-1" ref="description" id="description" v-model="description"
                    label="A brief description of your life so far"
                    textarea rows="2" color="teal"
                ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="mb-2">
                    <v-flex xs2 sm1>
                        <i class="mt-2 ml-1 teal--text fa-solid fa-mars-and-venus fa-2x"></i>
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-select
                        class="pt-0  pl-2" ref="gender"
                        v-model="gender" name="input-2" 
                        :items="items"
                        :rules="[v => !!v || 'Gender is required']"
                        label="Select Gender"
                        required color="teal"
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-user-tie fa-2x "> </i>                            
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-text-field 
                            name="input-3" 
                            class="pt-0 mt-0 pl-2" ref="profession"
                            id="profession" v-model="profession"
                            label="Enter Your Profession"
                            :rules="[v => !!v || 'Your profession is required']"
                            required
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="mb-2">
                    <v-flex class=" "  xs2 sm1>
                        <i class="teal--text fa-solid fa-language fa-2x "> </i>
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-text-field
                            name="input-4"  
                            class="pt-0 mt-0 pl-2" ref="language"
                            id="language" v-model="language"
                            label="Enter Your Language"
                            :rules="[v => !!v || 'Language is required']"
                            required
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-map-location-dot fa-2x "> </i>                            
                    </v-flex>
                    <v-flex xs9 sm10>
                        <v-text-field 
                            name="input-5" 
                            class="pt-0 mt-0 pl-2" v-model="residence"
                            id="residence" ref="residence"
                            label="Residential Address"
                            :rules="[v => !!v || 'Your Residential Address is required']"
                            required
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout> 
            </v-flex>
        </v-layout>
        <div class="text-xs-center">
            <v-btn
                color="primary" :disabled="isDisabled"
                class="me-3 mt-3"
                @click="updateAccountDetails()"
            >
            Save changes
            </v-btn>
        </div>
      </div>
    </v-card>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                isBtnDisabled: true,
                loading: false,
                items: [
                    'Male',
                    'Female',
                ],
                description: "",
                file: "",
                gender: "",
                language: "",
                profession: "",
                residence: "",
              
            }
        },
        
        methods:{
            setAccountDetails(){
                this.gender = this.$store.state.user_info["user_profile"]["gender"]
                this.language = this.$store.state.user_info["user_profile"]["language"]
                this.profession = this.$store.state.user_info["user_profile"]["profession"]
                this.residence = this.$store.state.user_info["user_profile"]["address"]
                this.description = this.$store.state.user_info["user_worker_info"]["personal_info"]
            },

            async updateAccountDetails(){
                console.log('updateAccountDetails')
                if(this.gender === ""){
                    this.$store.commit('setSnackBarMessage', "Please select gender.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.gender.focus()  // this causes a an auto fucos to the element
                }
                else if(this.residence === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your residence.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.residence.focus()  // this causes a an auto fucos to the element
                }
                else if(this.profession === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your profession.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.profession.focus()  // this causes a an auto fucos to the element
                }
                else if(this.birthday === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your birthday.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.birthday.focus()  // this causes a an auto fucos to the element
                }
                else if(this.language === ""){
                    this.$store.commit('setSnackBarMessage', "Please select a language")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.language.focus()  // this causes a an auto fucos to the element
                }
                else if(this.description === ""){
                    this.$store.commit('setSnackBarMessage', "Please input a brief description of who you are.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.description.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.loading = true
                    this.isBtnDisabled = true
                    const formData = {
                        gender: this.gender,
                        residence: this.residence,
                        profession: this.profession,
                        birthday: this.birthday,
                        language: this.language,
                        description: this.description,
                    }

                    await axios
                        .post('/api/v1/home/update_account_details/info/', formData)
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            if (response.data[0].category === "success"){
                                this.$router.push('/user_profile/account')
                            }
                            this.isBtnDisabled = false
                        })
                        .catch(error => {
                            if (error.response){
                                for (const property in error.response.data){
                                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.loading = false
                }

            },
        },

        mounted(){
            this.setAccountDetails() 
            this.$store.commit('setCloseURL', "/")
        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

    @media (max-width: 420px) {
        #reset_btn {
            margin-top: 0!important;
        }
    }

    @media (min-width: 420px) {
        #reset_btn {
            margin-top: 25px!important;
        }
    }
</style>
